import { format, set } from "date-fns";
import location from "../VectorWhite.png";
import placeholder from "../placeholder.png";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Link } from "react-router-dom";
import circleImg from "../circle-check-solid-white.svg";

import virtual from "../virtual.svg";

function AdCard(props) {
  let [org, setOrg] = useState(null);
  let [orgLocation, setOrgLocation] = useState(null);
  let [locationList, setLocationList] = useState([]);

  function distance(lat1, lat2, lon1, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 3956;

    // calculate the result
    let results = c * r;
    return Math.round(results * 100 + Number.EPSILON) / 100;
  }

  useEffect(() => {
    setLocationList([]);
    var locationListArray = [];
    setOrgLocation(props.promoOrgs.locations[0]);
    if (props.promoOrgs.locations.length > 0) {
      setOrgLocation(props.promoOrgs.locations[0]);
      props.promoOrgs.providers.map((org) => {
        if (org.cpid == props.promoOrgs.locations[0].cpid) {
          setOrg(org);
        }
      });
      props.promoOrgs.locationsAll.map((location) => {
        // console.log(location);
        if (location.cpid == props.promoOrgs.locations[0].cpid) {
          // setOrgLocation(location);
          console.log(location);
          locationListArray.push(location);
        }
      });
      console.log(locationListArray);
      setLocationList(locationListArray);
    } else {
      var randomSet = Math.floor(Math.random() * 2);
      // console.log(randomSet);
      if (randomSet == 1) {
        var randLocation = Math.floor(
          Math.random() * props.promoOrgs.locationsNational.length,
        );
        setOrgLocation(props.promoOrgs.locationsNational[randLocation]);
        // setOrgLocation(props.promoOrgs.locationsNational[0]);
        props.promoOrgs.providerNational.map((org) => {
          if (
            org.cpid == props.promoOrgs.locationsNational[randLocation].cpid
          ) {
            setOrg(org);
          }
        });

        props.promoOrgs.locationsNational.map((location) => {
          if (
            props.promoOrgs.locationsNational[randLocation].cpid ==
            location.cpid
          ) {
            // setOrgLocation(location);
            locationListArray.push(location);
          }
        });
        setLocationList(locationListArray);
      } else {
        var randLocation = Math.floor(
          Math.random() * props.promoOrgs.providersOnline.length,
        );
        setOrgLocation(props.promoOrgs.providersOnline[randLocation]);
        // setOrgLocation(props.promoOrgs.locationsNational[0]);
        props.promoOrgs.providers.map((org) => {
          if (org.cpid == props.promoOrgs.providersOnline[randLocation]) {
            setOrg(org);
          }
        });
      }
    }
  }, [props.promoOrgs]);

  const linkhandleClick = (idClick) => {
    // e.preventDefault();
    console.log("The link was clicked.", idClick);
    const dataClick = async () => {
      const data = await (
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/record-click-article?id=${idClick}`,
        )
      ).json();
      console.log("The link was clicked.");
    };
    dataClick();
  };

  const [isListVisible, setListVisibility] = useState(false);

  const handleClick = () => {
    setListVisibility(!isListVisible);
  };

  return (
    <div>
      {org && (
        <div id="resourcead" className="campAdCard">
          {org.ytLink != "" ? (
            <Link
              onClick={() => {
                linkhandleClick(org.cpid);
              }}
              to={
                org.websiteUrl +
                "?tutm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
              }
              target="_blank"
            >
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${org.ytLink}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </Link>
          ) : (
            <Link
              onClick={() => {
                linkhandleClick(org.cpid);
              }}
              to={
                org.websiteUrl +
                "?tutm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
              }
              target="_blank"
            >
              <div className="adcardimg">
                <img
                  alt="Camp Logo"
                  src={
                    "https://static.summercampscout.com/media/providers/" +
                    org.image
                  }
                />
              </div>
            </Link>
          )}
          <div className="twopromo">
            <h2 className="cardAdTitleAd">{org.title}</h2>
            {locationList.length > 1 && (
              <div>
                <h3 onClick={handleClick} className="promoLocationsList">
                  {locationList.length}&nbsp; LOCATIONS{" "}
                  {isListVisible ? "[-]" : "[+]"}
                </h3>

                {isListVisible && (
                  <ul className="promoLocationsListLinks">
                    {locationList.map((locationData) => {
                      if (locationData) {
                        return (
                          <li key={locationData.rangeKey}>
                            <img className="cardlocation" src={location} />
                            {locationData.description}&nbsp;
                            <span>
                              &nbsp;(
                              {distance(
                                props.lat,
                                parseFloat(locationData.geoJson.split(",")[0]),
                                props.lon,
                                parseFloat(locationData.geoJson.split(",")[1]),
                              )}{" "}
                              miles from{" "}
                              {props.zip == "" || props.zip == null
                                ? "Zip"
                                : props.zip}
                              )
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
                {!isListVisible && (
                  <div className="mainLocation">
                    <h3 className="providercardLocation">
                      <img className="cardlocation" src={location} />
                      {locationList[0].description}{" "}
                      <span>&nbsp;(nearest location)</span>
                    </h3>
                    <p className="addistance">
                      {distance(
                        props.lat,
                        parseFloat(locationList[0].geoJson.split(",")[0]),
                        props.lon,
                        parseFloat(locationList[0].geoJson.split(",")[1]),
                      )}{" "}
                      miles from{" "}
                      {props.zip == "" || props.zip == null ? "Zip" : props.zip}{" "}
                    </p>
                  </div>
                )}
              </div>
            )}

            {org.virtual && org.virtual === true ? (
              <div className="virtualCardImg">
                <img className="cardVirtual" src={virtual} />
              </div>
            ) : (
              <div>
                {locationList.length <= 1 && (
                  <h3 className="campcardLocation">
                    <img className="cardlocation" src={location} />
                    {orgLocation.description}
                  </h3>
                )}
              </div>
            )}
            {org.specialNeeds == true ? (
              <p key={"special" + org.cpid} className="specialNeeds">
                <img className="cardlocation" src={circleImg} /> Special Needs
                Friendly
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="rightCard threepromo"></div>
          <div key={"desc-" + org.cpid}>
            <p className="addescriptiontext">{org.description}</p>
            <div className="promoTextSectionAd">
              <Link
                onClick={() => {
                  linkhandleClick(org.cpid);
                }}
                id="pp-cta"
                to={
                  org.websiteUrl +
                  "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"
                }
                target="_blank"
                className="providerCardButton"
              >
                CHECK AVAILABILITY
              </Link>
              <p className="promotedtextHome">Promoted</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdCard;
