import { Outlet, Link } from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col, Table } from "react-bootstrap";
import FAQ from "../components/faq";

import { useNavigate } from "react-router-dom";
import PageHeader from "../components/pageHeader";
import DemoCard from "../components/DemoCard";
import DemoPromoCard from "../components/DemoPromoCard";
import AdDemoCard from "../components/AdDemoCard";
import link from "../link.svg";

import arrowup from "../arrow-up-right-dots-solid.svg";
import group from "../Group.svg";
import local from "../localmarketing.svg";
import process from "../processes.svg";
import seo from "../seo.svg";
import star from "../star.png";

const query = `query{
  pagesCollection(where: { slug: "summercampmarketing" }, limit: 1){
    items{
      title
      metaDescription
      content
      componentsCollection{
        total
        items{
          __typename
          ... on ComponentLongTest {
            title
            content
          }
          ... on ComponentYouTubeVideo{
            youTubeId
          }
          ... on ComponentParent{
            title
          }

        }


        }
      }
      }
  }`;

export default function Marketing() {
  let [email, setEmail] = useState(false);
  const [page, setPage] = useState(null);
  const [faqs, setFaqs] = useState(null);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emailName, setEmailName] = useState("");
  const [company, setCompany] = useState("");
  const [fMessage, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [index, setIndex] = useState(0);

  const [campTotal, setCampTotal] = useState("");
  const [locationTotal, setLocationTotal] = useState("");
  const [providerTotal, setProviderTotal] = useState("");

  const [activeSection, setActiveSection] = useState(null);
  const [isBottom, setIsBottom] = useState(false);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  // const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);

  const sections = useMemo(
    () => [
      { ref: section1Ref, id: "section1" },
      { ref: section2Ref, id: "section2" },
      { ref: section4Ref, id: "section4" },
      { ref: section5Ref, id: "section5" },
      { ref: section6Ref, id: "section6" },
      { ref: section7Ref, id: "section7" },
    ],
    [],
  );

  useEffect(() => {
    window
      .fetch(
        `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/totals`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => response.json())
      .then((data) => {
        setCampTotal(data.camps);
        setLocationTotal(data.locations);
        setProviderTotal(data.providers);
      });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      sections.forEach((section) => {
        const element = section.ref.current;
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= 118) {
            setActiveSection(section.id);
          }
        }
      });
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setIsBottom(true);
        setActiveSection("section7");
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const toggleFAQ = (index) => {
    setIndex(index);
  };

  const handleScrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      window.scrollBy(0, -118); // Adjust the scroll position by 118px
    }, 500);
  };

  const navigate = useNavigate();
  //   useEffect(() => {
  //     navigate("/contact");
  //   }, []);
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validate = () => {
    var emailvalid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      emailName,
    );
    console.log(emailvalid);
    if (
      (fName.length > 1) &
      (lName.length > 1) &
      (fMessage.length > 1) &
      emailvalid
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    var isValid = validate();
    console.log(isValid + " is valid");
    setIsValid(isValid);
  }, [fName, lName, emailName, fMessage]);

  useEffect(() => {
    window
      .fetch(
        `https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authenticate the request
            Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
          },
          // send the GraphQL query
          body: JSON.stringify({ query }),
        },
      )
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setFaqs(data.pagesCollection.items[3]);
        setPage(data.pagesCollection.items[0]);
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            data.pagesCollection.items[0].metaDescription,
          );
      });
  }, []);

  useEffect(() => {
    document.title = "Summer Camp Marketing | Summer Camp Scout";
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setEmail(true);
  };

  if (!page) {
    return (
      <div className="wrapper">
        <main className="page-main">
          <div>Loading...</div>
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <PageHeader title={"Advertising"} />
        <div className="contactWrapper">
          <Container className="contact-content">
            <Col
              className="adTitle"
              md={{ span: 12 }}
              lg={{ span: 10, offset: 1 }}
            >
              <div className="pageContentAdvertising">
                <ReactMarkdown>{page.content}</ReactMarkdown>
              </div>
            </Col>
          </Container>
          <Container className="pageContentAdvertisingContent">
            <Row>
              <Col md={{ span: 12 }} lg={{ span: 6 }}>
                <ReactMarkdown>
                  {page.componentsCollection.items[0].content}
                </ReactMarkdown>
                <div className="adBtns">
                  <a
                    href="https://provider.summercampscout.com"
                    className="orangeBtn"
                    target="_blank"
                  >
                    SUBMIT CAMPS
                  </a>
                  <button
                    onClick={() => handleScrollToSection(section2Ref)}
                    className="borderBtn"
                  >
                    VIEW DEMO
                  </button>
                </div>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 6 }}>
                <div className="hearoImg">
                  <img
                    src="https://images.ctfassets.net/y1wwfz3pz3en/jXlnO6g97II0uy3lvWPRh/1f876fec058bfc670fdf7eba479c65ce/AdobeStock_111918463.jpeg"
                    alt="Summer Camp Scout"
                  />
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col lg={{ span: 2 }}>
                <nav className="marketing-links">
                  <h3>QUICKLINKS</h3>

                  <button
                    onClick={() => handleScrollToSection(section1Ref)}
                    className={activeSection === "section1" ? "active" : ""}
                  >
                    Features & Benefits
                  </button>
                  <button
                    onClick={() => handleScrollToSection(section2Ref)}
                    className={activeSection === "section2" ? "active" : ""}
                  >
                    View Demo
                  </button>
                  <button
                    onClick={() => handleScrollToSection(section4Ref)}
                    className={activeSection === "section4" ? "active" : ""}
                  >
                    Platform Stats
                  </button>
                  <button
                    onClick={() => handleScrollToSection(section5Ref)}
                    className={activeSection === "section5" ? "active" : ""}
                  >
                    Listing Options
                  </button>
                  <button
                    onClick={() => handleScrollToSection(section6Ref)}
                    className={activeSection === "section6" ? "active" : ""}
                  >
                    Listing Examples
                  </button>
                  <button
                    onClick={() => handleScrollToSection(section7Ref)}
                    className={activeSection === "section7" ? "active" : ""}
                  >
                    FAQ
                  </button>
                </nav>
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 10 }} ref={section1Ref}>
                <Row>
                  <Col md={{ span: 12 }} className="marketingHeader">
                    <h2 className="sectionTitle">FEATURES & BENEFITS</h2>
                    <div className="orangeDevider"></div>
                    <p className="sectionCopy">
                      Join Summer Camp Scout and benefit from our
                      industry-leading features. You won’t find a better value
                      to promote your summer camp offerings with ease anywhere
                      else.
                    </p>
                  </Col>
                  <Col md={{ span: 12 }}>
                    <Row>
                      <Col md={{ span: 8 }} className="marketingCardOne">
                        <Row>
                          <Col
                            md={{ span: 6 }}
                            className="marketingCardWrapper"
                          >
                            <div className="marketingCard">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={local}
                                  alt="Experience our platform"
                                />
                                <h3>TARGETED EXPOSURE</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Our platform drives impressions and clicks
                                  right where you need them, ensuring parents
                                  near your location see your camps first.
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={{ span: 6 }}
                            className="marketingCardWrapper"
                          >
                            <div className="marketingCard">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={group}
                                  alt="Experience our platform"
                                />
                                <h3>SPECIAL NEEDS SUPPORT</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Designate camps as “Special Needs Friendly”
                                  and our filter option helps parents
                                  effortlessly find and choose the right camp
                                  for kids.{" "}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={{ span: 6 }}
                            className="marketingCardWrapper"
                          >
                            <div className="marketingCard">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={process}
                                  alt="Experience our platform"
                                />
                                <h3>EASY SELF MANAGEMENT</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Our Provider Portal allows you to add and edit
                                  your information and camp listings. Update
                                  your campaign’s media and imagery throughout
                                  the year to reflect your current offerings
                                  with ease.
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={{ span: 6 }}
                            className="marketingCardWrapper"
                          >
                            <div className="marketingCard">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={seo}
                                  alt="Experience our platform"
                                />
                                <h3>SEO BENEFITS</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Our platform drives impressions and clicks
                                  right where you need them, ensuring parents
                                  near your location see your camps first.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={{ span: 4 }} className="marketingCardSectionTwo">
                        <h3 className="sectoionTwoHeader">
                          PROVIDER PROMOTION EXCLUSIVE
                        </h3>
                        <Row>
                          <Col
                            md={{ span: 12 }}
                            className="marketingCardWrapperTwo"
                          >
                            <div className="marketingCardTwo">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={star}
                                  alt="Experience our platform"
                                />
                                <h3>PRIORITY PLACEMENT</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Our year-long provider promotions feature on
                                  top of camp search results, in our
                                  comprehensive provider directory, and
                                  alongside engaging content in our resource
                                  center.
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={{ span: 12 }} className="">
                            <div className="marketingCardTwo">
                              <div className="cardTitleFeatured">
                                <img
                                  className="cardImg"
                                  src={arrowup}
                                  alt="Experience our platform"
                                />
                                <h3>PERFORMANCE REPORTING</h3>
                              </div>
                              <div className="marketingCardFeaturedText">
                                <p>
                                  Keep an eye on your promotion by monitoring
                                  impressions and clicks in real-time in our
                                  Provider Portal. Our portal also testing of
                                  different creative solutions for optimal
                                  performance.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md={{ span: 12 }}
                        ref={section2Ref}
                        className="marketingHeader marketingSectionSpace"
                      >
                        <h2 className="sectionTitle">
                          EXPERIENCE OUR PLATFORM
                        </h2>
                        <div className="orangeDevider"></div>
                        <p className="sectionCopy">
                          View our Product Demo and see the ease-of-use and
                          power of the Summer Camp Scout Marketing Platform.
                        </p>
                      </Col>
                      <Col md={{ span: 12 }}>
                        <Row className="videoSec">
                          <Col md={{ span: 12 }} lg={{ span: 8 }}>
                            <div className="video-responsive-advertise">
                              <iframe
                                width="853"
                                height="480"
                                src={`https://www.youtube.com/embed/${page.componentsCollection.items[2].youTubeId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                              />
                            </div>
                          </Col>
                          <Col
                            className="steps"
                            md={{ span: 12 }}
                            lg={{ span: 4 }}
                          >
                            <h3>3 EASY STEPS</h3>
                            <ol className="marketingList">
                              <li>
                                <h4>CREATE YOUR ORGANIZATION</h4>
                                <p>
                                  Provide your information and decide if you
                                  want to promote your organization (see
                                  Provider Promo Benefits) or not.
                                </p>
                              </li>
                              <li>
                                <h4>ADD YOUR LOCATIONS</h4>
                                <p>
                                  Enter your locations where you hold camps.
                                  This allows us to showcase your promoted
                                  organization or camps to parents near those
                                  locations.
                                </p>
                              </li>
                              <li>
                                <h4>ADD INDIVIDUAL CAMPS</h4>
                                <p>
                                  Share your camp offerings with parents near
                                  you so they can easily find the best camps for
                                  their kids and build a summer schedule.
                                </p>
                              </li>
                            </ol>
                            <a
                              className="markeingLink"
                              href="http://provider.summercampscout.com"
                              target="_blank"
                            >
                              EXPERIENCE OUR PLATFORM{" "}
                              <img src={link} alt="Experience our platform" />
                            </a>
                          </Col>

                          <Col className="join-now">
                            <div>
                              <h2>JOIN SUMMER CAMP SCOUT NOW!</h2>
                              <p>
                                Connect with parents near you and start filling
                                your camp spots.
                              </p>
                            </div>
                            <div className="joinbtn">
                              <Link
                                to="https://provider.summercampscout.com/"
                                className="cta-submit"
                                target="_blank"
                              >
                                SUBMIT CAMPS
                              </Link>
                              <div className="">
                                <p>IT'S FREE</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="marketingStats" ref={section4Ref}>
                  <Col md={{ span: 12 }}>
                    <h2 className="statsTitle">
                      SUMMER CAMP SCOUT PLATFORM STATS
                    </h2>
                    <p className="statsCopy">
                      All stats are real-time metrics from our system
                    </p>
                  </Col>
                  <Col md={{ span: 4 }} className="statView">
                    <p className="statNum">
                      {providerTotal !== ""
                        ? Number(providerTotal).toLocaleString()
                        : ""}
                    </p>
                    <p className="statTitle">CAMP PROVIDERS</p>
                  </Col>
                  <Col md={{ span: 4 }} className="statView">
                    <p className="statNum">
                      {locationTotal !== ""
                        ? Number(locationTotal).toLocaleString()
                        : ""}
                    </p>
                    <p className="statTitle">LOCATIONS</p>
                  </Col>
                  <Col md={{ span: 4 }} className="statView">
                    <p className="statNum">
                      {campTotal !== ""
                        ? Number(campTotal + 4519).toLocaleString()
                        : ""}
                    </p>
                    <p className="statTitle">LISTED CAMPS</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    ref={section5Ref}
                    md={{ span: 12 }}
                    className="marketingHeader"
                  >
                    <h2 className="sectionTitle">LISTING OPTIONS</h2>
                    <div className="orangeDevider"></div>
                    <p className="sectionCopy">
                      Compare our offerings and find the perfect listing type
                      that fits your needs.
                    </p>
                  </Col>
                </Row>
                <Row className="ben-table">
                  <Table
                    className="adTable rounded-corners"
                    striped
                    responsive
                    bsPrefix
                  >
                    <thead className="tableHeader">
                      <tr>
                        <th></th>
                        <th>Standard</th>
                        <th>Premium</th>
                        <th>Organization Promotion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tableDescription">
                        <td className="tableBold">Benefits/Performance</td>
                        <td>
                          Camps appear in standard order on the search page.*
                        </td>
                        <td>
                          Add an image or video and puts the camp listing above
                          non-premium listings resulting in 100x+ impressions
                          and 20x more clicks.
                        </td>
                        <td>
                          Provider promotions are displayed between camp
                          listings, within the specified search radius.
                          Impression and click data for these promotions are
                          accessible in the Provider Portal.
                        </td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Camp Details</td>
                        <td className="tableMark">X</td>
                        <td className="tableMark">X</td>
                        <td className="tableMark"></td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Description</td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Image/Short Video</td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Priority Placement</td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Covers Multiple Camps</td>
                        <td className="tableMark"></td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Directory Listing</td>
                        <td className="tableMark"></td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr className="campTableRow">
                        <td className="tableBold">Resource Promotion</td>
                        <td className="tableMark"></td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr>
                        <td className="tableBold">Performance Tracking</td>
                        <td className="tableMark"></td>
                        <td className="tableMark"></td>
                        <td className="tableMark">X</td>
                      </tr>
                      <tr>
                        <td className="tableBold">Cost</td>
                        <td className="tableMark">$0</td>
                        <td className="tableMark">$49</td>
                        <td className="tableMark">$299*</td>
                      </tr>
                      <tr>
                        <td>Each Additional Location</td>
                        <td className="tableMark"></td>
                        <td className="tableMark"></td>
                        <td className="tableMark">$99</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="btndescriptionPrice">
                    <p>
                      * In addition to local promotions, we also offer
                      promotions for{" "}
                      <span>
                        virtual camps and national promotions for $599
                      </span>
                      .
                    </p>
                  </div>
                </Row>
                <Row></Row>
                <Row>
                  <Col
                    md={{ span: 7 }}
                    sm={{ span: 12 }}
                    ref={section6Ref}
                    className="examples"
                  >
                    <h3 className="adCardMainHeader">Camp Listing Examples</h3>
                    <DemoCard promoType={"Standard Listing"} />
                    <AdDemoCard promoType={"Premium Listing"} />
                  </Col>
                  <Col md={{ span: 5 }} sm={{ span: 12 }}>
                    <h3 className="adCardMainHeader">
                      Organization Promo Example
                    </h3>
                    <Col className="providerCard" lg="12">
                      <DemoPromoCard />
                    </Col>
                  </Col>
                  <Col md={{ span: 12 }} className="marketingFaq">
                    <h2 className="statsTitle">FAQ</h2>
                    <div className="orangeDevider"></div>
                    <p className="statsCopy"></p>
                  </Col>
                  <Col
                    md={{ span: 12 }}
                    ref={section7Ref}
                    className="marketing-faq"
                  >
                    <Row>
                      <div className="faqs">
                        <FAQ
                          faq={"How can I submit camps?"}
                          answer={`Please click on "Submit Camps" in the menu and create a FREE account in our [Provider Portal](https://provider.summercampscout.com "Provider Portal").

Once logged in, please add your camp organization, location and submit all your summer camps. We also offer a batch import option from a spreadsheet if you purchased an org promotion.`}
                          index={index}
                          valindex={0}
                          key={0}
                          toggleFAQ={toggleFAQ}
                        />
                        <FAQ
                          faq={"How long do individual camps stay promoted?"}
                          answer={`Individual camps stay promoted until the camp start date is in the past. They will also be visible in hte camp archive for any given year, e.g. [/archive/2023](https://summercampscout.com/archive/2023 2023 Summer Camp Archive) .`}
                          index={index}
                          valindex={1}
                          key={1}
                          toggleFAQ={toggleFAQ}
                        />
                        <FAQ
                          faq={"How long will my organization be promoted?"}
                          answer={`Once submitted all organizations stay promoted until October 15. Promotions for the subsequent year can be submitted again as early as November 1.`}
                          index={index}
                          valindex={2}
                          key={2}
                          toggleFAQ={toggleFAQ}
                        />
                        <FAQ
                          faq={
                            "Can I submit my camp organization instead of individual camps?"
                          }
                          answer={`Yes, for the 2024 summer camp season we added the abillity to promote a camp organization as part of camp search results. In addition, we launched our [Provider Directory](https://summercampscout.com/providers "Provider Directory") for additional exposure.`}
                          index={index}
                          valindex={3}
                          key={3}
                          toggleFAQ={toggleFAQ}
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
